class MrCMCampaignArchive extends HTMLElement {
	#messageHandler = ( event: MessageEvent ): void => {
		if ( !event.data || !event.data.cmList ) {
			return;
		}

		const fragment = new DocumentFragment();
		const originalList = document.createElement( 'ul' );
		const filteredList = document.createElement( 'ul' );

		originalList.innerHTML = event.data.cmList;

		// Throw on script tag leaks
		const hasScriptTag = !!( originalList.querySelector( 'script' ) );
		if ( hasScriptTag ) {
			throw new Error( 'Create Send Archive has script tag in list' );
		}

		// Throw on style tag leaks
		const hasStyleTag = !!( originalList.querySelector( 'style' ) );
		if ( hasStyleTag ) {
			throw new Error( 'Create Send Archive has style tag in list' );
		}

		originalList.querySelectorAll( 'li > a' ).forEach( ( a ) => {
			if ( !a.parentNode ) {
				// The query selector should prevent this, but you never know.
				return;
			}

			if ( 0 === a.innerHTML.indexOf( 'CKV' ) ) {
				filteredList.appendChild( a.parentNode );
			}
		} );

		fragment.appendChild( filteredList );

		requestAnimationFrame( () => {
			this.innerHTML = '';
			this.appendChild( fragment );
		} );
	};

	connectedCallback() {
		const iframe = document.createElement( 'iframe' );
		iframe.setAttribute( 'hidden', '' );
		iframe.setAttribute( 'sandbox', 'allow-scripts' );
		iframe.setAttribute( 'referrerpolicy', 'no-referrer' );

		// Note: it is possible to make this editor configurable by passing the createsend url as an attribute.
		const html = `
			<!DOCTYPE html>
			<html>
			<head>
				<meta name="referrer" content="no-referrer">
				<script src="https://muhka.createsend.com/t/y/p/nlkil/0/1/0/1/0/"></script>
				<script>
					window.parent.postMessage(
						{
							cmList: document.body.getElementsByTagName( 'ul' )[0].innerHTML,
						},
						'https://${location.hostname}'
					);
				</script>
			</head>
			<body>
			</body>
			</html>
		`;

		iframe.src = 'data:text/html;charset=utf-8,' + encodeURI( html );

		window.addEventListener( 'message', this.#messageHandler );

		requestAnimationFrame( () => {
			this.appendChild( iframe );
		} );
	}

	disconnectedCallback() {
		window.removeEventListener( 'message', this.#messageHandler );
	}
}

customElements.define( 'mr-cm-campaign-archive', MrCMCampaignArchive );
