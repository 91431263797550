( function() {
	let init = () => {
		if ( 'interactive' !== document.readyState && 'complete' !== document.readyState ) {
			return;
		}

		const download_items = document.querySelectorAll( '.js-download' );

		// if no elements, exit
		if ( !download_items || 1 > download_items.length ) {
			return;
		}

		// if touch device, exit
		if ( window.matchMedia( '(hover: none)' ).matches ) {
			return;
		}

		init = () => {};

		for ( let i = 0; i < download_items.length; i++ ) {
			const download_item = download_items[i];

			download_item.addEventListener( 'mouseenter', ( e ) => {
				const target = e.target;
				const target_image = target.getElementsByClassName( 'js-download__image' )[0];

				if ( !target || !target_image ) {
					return;
				}

				const mouseMoveHandler = ( mouseMoveEvent ) => {
					window.requestAnimationFrame( () => {
						if ( target_image ) {
							target_image.style.transform = `translate(${mouseMoveEvent.clientX - target.getBoundingClientRect().left - target.getBoundingClientRect().width}px, ${mouseMoveEvent.clientY - target.getBoundingClientRect().top - target.getBoundingClientRect().height}px) translateZ(0)`;
						}
					} );
				};

				target.addEventListener( 'mousemove', mouseMoveHandler );

				download_item.addEventListener( 'mouseleave', () => {
					target.removeEventListener( 'mousemove', mouseMoveHandler );
				}, {
					once: true,
				} );
			} );
		}
	};

	document.addEventListener( 'readystatechange', () => {
		init();
	}, false );

	init();
}() );
