import { defineCustomElement, BaseController } from '@mrhenry/wp--custom-elements-helpers';
import { scrollListener } from '../helpers/scroll.js';
import { resizeListener } from '../helpers/resize.js';

const META_TRESHOLD = 80;
const META_HEIGHT = 41;

defineCustomElement( 'mr-scroll-header', {
	attributes: [],
	controller: class extends BaseController {
		init() {
			this.cacheTresholds();
		}

		bind() {
			scrollListener.registerHandler( 'scroll-header', this.scrollHandler.bind( this ) );
			resizeListener.registerHandler( 'scroll-header', this.resizeHandler.bind( this ) );
		}

		scrollHandler( scrollPosition ) {
			if ( isNaN( scrollPosition ) ) {
				return;
			}

			if ( !window.matchMedia( '(min-width: 1024px)' ).matches ) {
				this.reset();

				return;
			}

			// META NAVIGATION ANIMATION
			if ( scrollPosition <= this.tresholds.start ) {
				this.el.style.transform = 'translateY(0) translateZ(0)';

				return;
			}

			if ( scrollPosition >= this.tresholds.end ) {
				this.el.style.transform = `translateY(-${META_HEIGHT}px) translateZ(0)`;

				return;
			}

			// we know the viewport is in between the treshold area from here on.
			const relativePosition = scrollPosition - this.tresholds.start;
			const relativeMax = this.tresholds.end - this.tresholds.start;
			const normalize = ( relativePosition / relativeMax );
			this.el.style.transform = `translateY(-${Math.round( normalize * META_HEIGHT )}px) translateZ(0)`;
		}

		resizeHandler() {
			this.scrollHandler( window.scrollY );
			this.cacheTresholds();
		}

		render() {
			window.requestAnimationFrame( () => {
				this.el.style.willChange = 'transform';

				this.scrollHandler( window.scrollY );
			} );
		}

		reset() {
			this.el.style.transform = 'none';
			this.el.style.opacity = '1';
		}

		cacheTresholds() {
			this.tresholds = {
				start: 0,
				end: META_TRESHOLD,
			};
		}
	},
} );
