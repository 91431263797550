class ResizeListener {

	constructor() {
		// initialize variables
		this.callbacks = {};
		this.scrollThrottle = false;

		// initialize listeners
		this.bind();
	}

	bind() {
		window.addEventListener( 'resize', () => {
			if ( 1 > Object.keys( this.callbacks ).length ) {
				return;
			}

			if ( this.timeout ) {
				window.cancelAnimationFrame( this.timeout );
			}

			this.timeout = window.requestAnimationFrame( () => {
				this.resizeHandler();
			} );
		}, true );
	}

	resizeHandler() {
		for ( const key in this.callbacks ) {
			this.callbacks[key]();
		}
	}

	registerHandler( key, fn ) {
		this.callbacks[key] = fn;
	}

	unregisterHandler( key ) {
		delete this.callbacks[key];
	}
}

export const resizeListener = new ResizeListener();
