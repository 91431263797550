import { defineCustomElement, BaseController } from '@mrhenry/wp--custom-elements-helpers';

defineCustomElement( 'mr-anchor-link', {
	attributes: [],
	controller: class extends BaseController {
		resolve() {
			return Promise.resolve( true );
		}

		bind() {
			this.on( 'click .js-anchor-link', ( event, target ) => {
				event.preventDefault();

				const anchor = target.getAttribute( 'href' ) || null;

				if ( null === anchor ) {
					return;
				}

				const element = document.querySelector( anchor ) || null;

				if ( null === element ) {
					return;
				}

				const header = document.querySelector( '.js-site-header' ).offsetHeight || 0;
				const scrollToTopOffset = element.getBoundingClientRect().top - 20 - header;

				scrollTo( {
					behavior: 'smooth',
					left: 0,
					top: scrollToTopOffset,
				} );
			}, window );
		}
	},
} );
